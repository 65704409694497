<template>
  <div>
    <CRow>
      <CCol col>
        <CCard>
          <CCardHeader>
            <strong> Modulo Cliente - Nuevo</strong>

            <router-link  class="btn btn-light float-right btn-sm"  :to="{ path: '/cliente/listar' }" append ><i class="fa fa-chevron-left"></i> Regresar</router-link>
          </CCardHeader>
          <CCardBody>
            <b-form id="Form" autocomplete="off" @submit.prevent="Validate">

              <b-tabs content-class="mt-3">
                <b-tab title="Datos del Cliente" active>
                  <b-row>
                    <b-col md="2">
                      <b-form-group label="Tipo de Documento:">
                        <b-form-select ref="city" v-model="client.document_type" :options="document_type"></b-form-select>
                      </b-form-group>
                      <small v-if="errors.document_type"  class="form-text text-danger" >Seleccione un tipo de documento</small>
                    </b-col>

                    <b-col md="2">
                      <b-form-group label="Nro Documento:">
                        <b-input-group>
                          <b-form-input v-model="client.document_number" class="form-control" ></b-form-input>
                          <b-input-group-append>
                            <b-button variant="primary"  @click="SearchClient"><b-icon icon="search"></b-icon></b-button>
                          </b-input-group-append>
                        </b-input-group>
                        <small v-if="errors.document_number" class="form-text text-danger" >{{error_document_number}}</small>
                      </b-form-group>
                    </b-col>

                    <b-col md="8">
                      <b-form-group label="Nombres:">
                        <b-form-input type="text"  v-model="client.name"></b-form-input>
                        <small v-if="errors.name" class="form-text text-danger" >Ingrese un nombre o razón social</small>
                      </b-form-group>
                    </b-col>

                    <b-col md="2">
                      <b-form-group label="Fecha de nacimiento:">
                        <b-form-input type="date"  v-model="client.birth_date"></b-form-input>
                        <small v-if="errors.birth_date" class="form-text text-danger" >Ingrese su fecha de nacimiento</small>
                      </b-form-group>
                    </b-col>

                    <b-col md="2">
                      <b-form-group label="Sexo:">
                        <b-form-select v-model="client.sex" :options="sex"></b-form-select>
                      </b-form-group>
                    </b-col>

                    <b-col md="2">
                      <b-form-group label="Estado Civil:">
                        <b-form-select v-model="client.civil_status" :options="civil_status"></b-form-select>
                      </b-form-group>
                    </b-col>

                    <b-col md="2">
                      <b-form-group label="Profesión y/o Ocupación:">
                        <b-form-select v-model="client.profession" :options="profession"></b-form-select>
                      </b-form-group>
                    </b-col>

                    <b-col md="2">
                      <b-form-group label="Telefono:">
                        <b-form-input type="text"  v-model="client.phone"></b-form-input>
                      </b-form-group>
                    </b-col>

                    <b-col md="2">
                      <b-form-group label="Celular:">
                        <b-form-input type="text"  v-model="client.cell_phone"></b-form-input>
                      </b-form-group>
                    </b-col>

                    <b-col md="4">
                      <b-form-group label="Email:">
                        <b-form-input type="email"  v-model="client.email"></b-form-input>
                      </b-form-group>
                    </b-col>

                    <b-col md="4">
                      <b-form-group label="Ubigeo:">
                        <v-select placeholder="Seleccione un ubigeo" label="text" class="w-100" v-model="mubigee" :options="ubigee"></v-select>
                      </b-form-group>
                    </b-col>

                    <b-col md="4">
                      <b-form-group label="Dirección:">
                        <b-form-input type="text"  v-model="client.address"></b-form-input>
                      </b-form-group>
                    </b-col>

                    <b-col md="4">
                      <b-form-group label="Dirección Secundaria:">
                        <b-form-input type="text"  v-model="client.secondary_address"></b-form-input>
                      </b-form-group>
                    </b-col>

                    <b-col md="6">
                      <b-form-group label="Observación:">
                        <b-form-textarea  v-model="client.observation"></b-form-textarea>
                      </b-form-group>
                    </b-col>

                    <b-col md="2">
                      <b-form-group label="Estado:">
                        <b-form-select v-model="client.state" :options="state"></b-form-select>
                      </b-form-group>
                    </b-col>

                    <b-col md="5"></b-col>
                    <b-col md="2">
                      <b-button type="submit" class="form-control" variant="primary" ><i class="fas fa-save"></i> Guardar (F4)</b-button>
                    </b-col>

                  </b-row>
                </b-tab>
                <b-tab title="Familiares">
                  <b-row>
                    <b-col md="2">
                      <b-form-group label="Parentesco:">
                        <b-form-select v-model="relative.relationship" :options="relationship"></b-form-select>
                      </b-form-group>
                    </b-col>
                    <b-col md="2">
                      <b-form-group label="Tipo de Documento:">
                        <b-form-select v-model="relative.document_type" :options="document_type"></b-form-select>
                      </b-form-group>
                    </b-col>

                    <b-col md="2">
                      <b-form-group label="Nro Documento:">
                        <b-input-group>
                          <b-form-input v-model="relative.document_number" class="form-control" ></b-form-input>
                          <b-input-group-append>
                            <b-button variant="primary"  @click="Searchrelative"><b-icon icon="search"></b-icon></b-button>
                          </b-input-group-append>
                        </b-input-group>
                        <small v-if="errors.document_number_relative" class="form-text text-danger" >{{error_document_number_relative}}</small>
                      </b-form-group>
                    </b-col>

                    <b-col md="5">
                      <b-form-group label="Nombres:">
                        <b-form-input type="text"  v-model="relative.name"></b-form-input>
                        <small v-if="errors.name_relative" class="form-text text-danger" >Ingrese un nombre o razón social</small>
                      </b-form-group>
                    </b-col>

                    <b-col md="1">
                      <b-form-group label=".">
                        <b-button type="button" @click="AddRelative" class="form-control" variant="primary">+</b-button>
                      </b-form-group>
                    </b-col>

                    <b-col md="12">
                      <div class="table-responsive mt-3 height-table">
                        <table class="table table-hover table-bordered">
                          <thead>
                            <tr>
                              <th width="3%" class="text-center">#</th>
                              <th width="15%" class="text-center">Parentesco</th>
                              <th width="10%" class="text-center">Tipo Doc.</th>
                              <th width="15%" class="text-center">Nro Doc.</th>
                              <th width="40%" class="text-center">Nombre</th>
                              <th width="5%" class="text-center"></th>
                            </tr>
                          </thead>
                          <tbody v-for="(item, it) in client.relatives" :key="it">
                            <tr>
                              <td class="text-center">{{ it + 1 }}</td>
                              
                              <td class="text-left">{{ NameRelationship(item.relationship) }}</td>
                              <td class="text-left">{{ CodeDocumentType(item.document_type) }}</td>
                              <td class="text-left">{{ item.document_number}}</td>
                              <td class="text-left">{{ item.name}}</td>
                              <td class="text-center">
                                <b-button type="button" @click="DeleteRelative(it)" variant="danger"><i class="fas fa-trash"></i></b-button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </b-col>

                    <b-col md="5"></b-col>
                    <b-col md="2">
                      <b-button type="submit" class="form-control" variant="primary" ><i class="fas fa-save"></i> Guardar (F4)</b-button>
                    </b-col>

                  </b-row>
                </b-tab>
              </b-tabs>

            </b-form>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>


    <LoadingComponent :is-visible="isLoading"/>
    <Keypress key-event="keyup" :key-code="115" @success="Validate" />
  </div>
</template>

<script>

import vSelect from "vue-select";
import 'vue-select/dist/vue-select.css';
import "vue-select/src/scss/vue-select.scss";


const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
var moment = require("moment");

import { mapState,mapMutations,mapActions } from "vuex";
import EventBus from "@/assets/js/EventBus";
import ApiQuery from "@/assets/js/APIQuery";
import CodeToName from "@/assets/js/CodeToName";
// components
import LoadingComponent from './../pages/Loading'
export default {
  name: "UsuarioAdd",
  components:{
      vSelect,
      Keypress: () => import('vue-keypress'),
      LoadingComponent,
  },
  data() {
    return {
      isLoading: false,
      module: 'Client',
      role: 2,
      client: {
        id_client:'',
        id_branch_office:'',
        document_type:1,
        document_number:'',
        name:'',
        birth_date:'',
        sex:'',
        phone:'',
        cell_phone:'',
        civil_status:'',
        profession:'',
        email:'',
        ubigee:'',
        address:'',
        secondary_address:'',
        observation:'',
        state:1,
        now_date: moment(new Date()).local().format("YYYY-MM-DD"),
        relatives:[],
      },
      relative:{
        id_relative:'',
        relationship:'',
        id_client:'',
        document_type:1,
        document_number:'',
        name:'',
        state:1,
      },
      state:[
        {value:'1',text:'Activo'},
        {value:'0',text:'Inactivo'},
      ],
      civil_status:[
        {value:"", text:"-- Seleccione --"},
        {value:"1", text:"Soltero"},
        {value:"2", text:"Casado"},
        {value:"3", text:"Viudo"},
        {value:"4", text:"Divorciado"},
        {value:"5", text:"Conviviente"},
      ],
      mubigee:null,
      ubigee: [],
      error_document_number:'',
      error_document_number_relative:'',
      document_type: [
        {value: 1 , text : 'DNI'},
        {value: 6 , text : 'RUC'},
        {value: 4 , text : 'CARNET DE EXTRANJERIA'},
        {value: 7 , text : 'PASAPORTE'},
        {value: 0 , text : 'OTROS'},
      ],
      sex:[
        {value: '', text:'- Seleccione -'},
        {value: 'Hombre', text:'Hombre'},
        {value: 'Mujer', text:'Mujer'},
      ],

      profession:[
        { value:'', text: '- Seleccione -'},
        { value:'Comerciante Formal', text: 'Comerciante Formal'},
        { value:'Comerciante Informal', text: 'Comerciante Informal'},
        { value:'Profesional', text: 'Profesional'},
        { value:'Agricultor', text: 'Agricultor'},
        { value:'Otros', text: 'Otros'},
      ],

      allowed_age: {
        minimum_age:0,
        maximum_age:0,
      },
      relationship:[
        { value : '', text : '-- Seleccione --' },
        { value : '1', text: 'ESPOSO (A)' },
        { value : '2', text: 'HIJO (A)' },
        { value : '3', text: 'PADRE' },
        { value : '4', text: 'MADRE' },
        { value : '5', text: 'TIO (A)' },
        { value : '6', text: 'SOBRINO (A)' },
        { value : '7', text: 'ABUELO (A)' },
        { value : '8', text: 'HERMANO (A)' },
        { value : '9', text: 'CONYUGE' },
        { value : '10', text: 'PRIMO (A)' },
        { value : '11', text: 'OTROS' },
      ],
      errors: {
        document_number:false,
        name: false,
        birth_date:false,


        document_number_relative:false,
        name_relative:false,
      },
      validate: false,
    };
  },
  mounted() {
    this.ListUbigeos();
    this.GetAllowedAges();
  },
  methods: {
    AddClient,
    Validate,
    SearchClient,
    Searchrelative,
    ListUbigeos,

    AddRelative,
    DeleteRelative,
    CodeDocumentType,
    NameRelationship,
    GetAllowedAges,
  },

  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
    user: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user;
    },
  },
};

function CodeDocumentType(code) {
  return CodeToName.NameDocumentType(code.toString());
}

function GetAllowedAges() {

  let url = this.url_base + "data/client-get-allowed-ages";
  let me = this;
  axios({
      method: "GET",
      url: url,
    }).then(function (response) {
     if (response.data.status == 200) {
        me.allowed_age.minimum_age = parseFloat(response.data.result.minimum_age);
        me.allowed_age.maximum_age = parseFloat(response.data.result.maximum_age);
     }
  })
}

function ListUbigeos() {
  this.ubigee = [];
  let url = this.url_base + "list-ubigee";
  let me = this;
  axios({
      method: "GET",
      url: url,
    }).then(function (response) {
      for (let index = 0; index < response.data.length; index++) {
      const element = response.data[index];
      me.ubigee.push({value:element.id_ubigee,text:element.department+', '+element.province+', '+element.district })
    }
  })
}

function SearchClient() {

  let me = this;
  if (me.client.document_type == 1) {
      if (me.client.document_number.length == 8) {
        me.errors.document_number = false;
        ApiQuery.SearchDni(me.client.document_number).then((data) => {
          if (data.status == 200) {
            me.client.name = data.razon_social;
            me.client.address = data.direccion;
          }else{
            me.client.name = '';
            me.client.address = '';
          }
            
        });
      }else{
        me.errors.document_number = true;
        me.error_document_number = 'El nro de ducumento debe contener 8 digitos';
      }
  }

  if (me.client.document_type == 6) {
      if (me.client.document_number.length == 11) {
        me.errors.document_number = false;
        ApiQuery.SearchRuc(me.client.document_number).then((data) => {
          if (data.status == 200) {
            me.client.name = data.razon_social;
            me.client.address = data.direccion;
          }else{
            me.client.name = '';
            me.client.address = '';
          } 
        });
      }else{
        me.errors.document_number = true;
        me.error_document_number = 'El nro de ducumento debe contener 11 digitos';
      }
  }

}

function Searchrelative() {

  let me = this;
  if (me.relative.document_type == 1) {
      if (me.relative.document_number.length == 8) {
        me.errors.document_number = false;
        ApiQuery.SearchDni(me.relative.document_number).then((data) => {
          if (data.status == 200) {
            me.relative.name = data.razon_social;
          }else{
            me.relative.name = '';
          }
            
        });
      }else{
        me.errors.document_number_relative = true;
        me.error_document_number_relative = 'El nro de ducumento debe contener 8 digitos';
      }
  }

  if (me.relative.document_type == 6) {
      if (me.relative.document_number.length == 11) {
        me.errors.document_number = false;
        ApiQuery.SearchRuc(me.relative.document_number).then((data) => {
          if (data.status == 200) {
            me.relative.name = data.razon_social;
          }else{
            me.relative.name = '';
            
          } 
        });
      }else{
        me.errors.document_number_relative = true;
        me.error_document_number_relative = 'El nro de ducumento debe contener 11 digitos';
      }
  }

}

function AddClient() {
  let me = this;
  let url = me.url_base + "client/add";
  me.client.id_branch_office = me.user.id_branch_office;
  me.client.ubigee = me.mubigee == null ? '': me.mubigee.value;
  let data = me.client;
  me.isLoading = true;
  axios({
    method: "POST",
    url: url,
    data: data,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module,role: me.role },
  })
    .then(function (response) {
      if (response.data.status == 201) {
        me.$router.push({ name: "ClientList" });
        Swal.fire({ icon: 'success', text: response.data.message, timer: 3000,})
      }else{
        Swal.fire({ icon: 'error', text: response.data.message, timer: 3000,})
      }
      me.isLoading = false;
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      me.isLoading = true;
    });
}

function Validate() {
  if (this.client.document_type == 1) {
    this.errors.document_number = this.client.document_number.length != 8 ? true : false;
    this.error_document_number = 'El nro de ducumento debe contener 8 digitos';
  }else if(this.client.document_type == 6){
    this.errors.document_number = this.client.document_number.length != 11 ? true : false;
    this.error_document_number = 'El nro de ducumento debe contener 11 digitos';
  }else if(this.client.document_type == 4){
    this.errors.document_number = this.client.document_number.length != 12 ? true : false;
    this.error_document_number = 'El nro de ducumento debe contener 12 digitos';
  }else if(this.client.document_type == 7){
    this.errors.document_number = this.client.document_number.length != 12 ? true : false;
    this.error_document_number = 'El nro de ducumento debe contener 12 digitos';
  }else{
    this.errors.document_number = this.client.document_number.length == 0 ? true : false;
    this.error_document_number = 'Ingrese un nro de documento';
  }

  this.errors.name = this.client.name.length == 0 ? true : false;
  this.errors.birth_date = this.client.birth_date.length == 0 ? true : false;
  
  if (this.errors.document_number) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.name) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.birth_date) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }


  const birth_date = moment(this.client.birth_date);
  const date_now = moment(this.client.date_now);
  if (birth_date.isValid()) {
    const client_age = date_now.diff(birth_date, 'years');
    if (parseFloat(client_age) < parseFloat(this.allowed_age.minimum_age) || parseFloat(client_age) > parseFloat(this.allowed_age.maximum_age)) {
      Swal.fire({ icon: 'warning', text: 'La edad Permitido para registrar un cliente es mayor e igual que ' + this.allowed_age.minimum_age + ' y menor o igual a '+ this.allowed_age.maximum_age , timer: 2000,}); 
      return false;
    }
  } else {
    Swal.fire({ icon: 'warning', text: 'Ingrese una fecha de nacimiento correcta', timer: 2000,}); 
    return false;
  }

  let me = this;
  Swal.fire({
    title: "Esta seguro de registrar el cliente ?",
    text: "No podrás revertir esto!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Si, Estoy de acuerdo!",
  }).then((result) => {
    if (result.value) {
      this.AddClient();
    }
  });
}

function AddRelative() {
  this.errors.relationship = this.relative.relationship.length == 0 ? true : false;

  if (this.relative.document_type == 1) {
    this.errors.document_number = this.relative.document_number.length != 8 ? true : false;
    this.error_document_number = 'El nro de ducumento debe contener 8 digitos';
  }else if(this.relative.document_type == 6){
    this.errors.document_number = this.relative.document_number.length != 11 ? true : false;
    this.error_document_number = 'El nro de ducumento debe contener 11 digitos';
  }else if(this.relative.document_type == 4){
    this.errors.document_number = this.relative.document_number.length != 12 ? true : false;
    this.error_document_number = 'El nro de ducumento debe contener 12 digitos';
  }else if(this.relative.document_type == 7){
    this.errors.document_number = this.relative.document_number.length != 12 ? true : false;
    this.error_document_number = 'El nro de ducumento debe contener 12 digitos';
  }else{
    this.errors.document_number = this.relative.document_number.length == 0 ? true : false;
    this.error_document_number = 'Ingrese un nro de documento';
  }

  this.errors.name = this.relative.name.length == 0 ? true : false;
  

  if (this.errors.relationship) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.document_number_relative) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.name_relative) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }


  let relative = {
    relationship: this.relative.relationship,
    document_type: this.relative.document_type,
    document_number: this.relative.document_number,
    name: this.relative.name,
    state: 1,
  }

  this.relative.relationship = '';
  this.relative.document_number = '';
  this.relative.name = '';

  this.client.relatives.push(relative);

}

function DeleteRelative(index) {
  this.client.relatives.splice(index, 1);
}

function NameRelationship(code) {
  for (let index = 0; index < this.relationship.length; index++) {
    const element = this.relationship[index];
    if (element.value == code) {
      return element.text;
    }
  }
}
</script>
